import axios from 'axios'

export default {
  all() {
    return axios
      .get('/api/partner_admin/users', {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  showUser(userID) {
    return axios
      .get(`/api/partner_admin/users/${userID}`, {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  updateUser(user) {
    return axios
      .put(
        `/api/partner_admin/users/${user.id}`,
        {
          user: user,
          group_ids: user.group_ids
        },
        {
          handleErrors: true
        }
      )
      .then(res => {
        return res.data
      })
  },
  deleteUser(userID) {
    return axios
      .delete(`/api/partner_admin/users/${userID}`, {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  mockUser(userID) {
    return axios
      .get(`/api/partner_admin/users/${userID}/mock_user`, {
        params: {},
        handleErrors: false
      })
      .then(
        res => {
          return res.data
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
  }
}
