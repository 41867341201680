<template>
  <partner-admin-page-card pageName="Edit User" iconClass="fa fa-users fa-lg">
    <v-wait for="fetchingDetails">
      <template slot="waiting">
        <content-placeholders :rounded="true">
          <content-placeholders-text :lines="20"></content-placeholders-text>
        </content-placeholders>
      </template>
      <div>
        <div class="row">
          <div class="col-sm-12 col-lg-12">
            <b-form-group
              label="First, Last Name"
              label-for="name"
              :label-cols="3"
              :horizontal="true"
            >
              <div class="row">
                <div class="col-sm-6">
                  <b-form-input
                    id="name"
                    type="text"
                    placeholder="First Name"
                    v-model="user.first_name"
                    :class="{
                      'is-invalid': $v.user.first_name.$error,
                      'is-valid': !$v.user.first_name.$invalid
                    }"
                    @blur="$v.user.first_name.$touch()"
                  ></b-form-input>
                  <b-form-invalid-feedback v-if="!$v.user.first_name.required"
                    >First Name can't be blank</b-form-invalid-feedback
                  >
                  <b-form-invalid-feedback v-if="!$v.user.first_name.alphaNum"
                    >First Name can be only alphanumeric
                    characters</b-form-invalid-feedback
                  >
                </div>
                <div class="col-sm-6">
                  <b-form-input
                    id="name"
                    type="text"
                    placeholder="Last Name"
                    v-model="user.last_name"
                    :class="{
                      'is-invalid': $v.user.last_name.$error,
                      'is-valid': !$v.user.last_name.$invalid
                    }"
                    @blur="$v.user.last_name.$touch()"
                  ></b-form-input>
                  <b-form-invalid-feedback v-if="!$v.user.last_name.required"
                    >Last Name can't be blank</b-form-invalid-feedback
                  >
                  <b-form-invalid-feedback v-if="!$v.user.last_name.alphaNum"
                    >Last Name can be only alphanumeric
                    characters</b-form-invalid-feedback
                  >
                </div>
              </div>
            </b-form-group>

            <b-form-group
              label="Email"
              label-for="email"
              :label-cols="3"
              :horizontal="true"
            >
              <div class="row">
                <div class="col-sm-6">
                  <b-form-input
                    id="email"
                    type="email"
                    placeholder="Email"
                    v-bind:value="user.email"
                    readonly
                    :class="{
                      'is-invalid': $v.user.email.$error,
                      'is-valid': !$v.user.email.$invalid
                    }"
                  ></b-form-input>
                  <b-form-invalid-feedback v-if="!$v.user.email.required"
                    >Email can't be blank</b-form-invalid-feedback
                  >
                  <b-form-invalid-feedback v-if="!$v.user.email.email">
                    Please provide a valid email
                    address</b-form-invalid-feedback
                  >
                </div>
              </div>
            </b-form-group>

            <b-form-group
              label="Company"
              label-for="company"
              :label-cols="3"
              :horizontal="true"
            >
              <div class="row">
                <div class="col-sm-6">
                  <b-form-input
                    id="company"
                    type="text"
                    placeholder="Company"
                    v-model="user.company"
                    :class="{
                      'is-invalid': $v.user.company.$error,
                      'is-valid': !$v.user.company.$invalid
                    }"
                    @blur="$v.user.company.$touch()"
                  ></b-form-input>
                  <b-form-invalid-feedback v-if="!$v.user.company.required"
                    >Company can't be blank</b-form-invalid-feedback
                  >
                </div>
              </div>
            </b-form-group>

            <b-form-group
              label="Title"
              label-for="title"
              :label-cols="3"
              :horizontal="true"
            >
              <div class="row">
                <div class="col-sm-6">
                  <b-form-input
                    id="title"
                    type="text"
                    placeholder="Title"
                    v-model="user.title"
                    :class="{
                      'is-invalid': $v.user.title.$error,
                      'is-valid': !$v.user.title.$invalid
                    }"
                    @blur="$v.user.title.$touch()"
                  ></b-form-input>
                  <b-form-invalid-feedback v-if="!$v.user.title.required"
                    >Title can't be blank</b-form-invalid-feedback
                  >
                </div>
              </div>
            </b-form-group>

            <b-form-group
              label="Phone"
              label-for="phone"
              :label-cols="3"
              :horizontal="true"
            >
              <div class="row">
                <div class="col-sm-6">
                  <cleave
                    id="phone"
                    type="text"
                    placeholder="Phone number"
                    v-model="user.phone"
                    :options="phoneOptions"
                    class="form-control"
                    :class="{
                      'is-invalid': $v.user.phone.$error,
                      'is-valid': !$v.user.phone.$invalid
                    }"
                    @blur="$v.user.phone.$touch()"
                  ></cleave>
                  <b-form-invalid-feedback v-if="!$v.user.phone.required"
                    >Phone can't be blank</b-form-invalid-feedback
                  >
                  <b-form-invalid-feedback
                    v-if="!$v.user.phone.mustMatchPhoneLength"
                    >Phone number is not valid.</b-form-invalid-feedback
                  >
                </div>
              </div>
            </b-form-group>

            <b-form-group
              label="User Role"
              label-for="user_role"
              :label-cols="3"
              :horizontal="true"
            >
              <div class="row">
                <div class="col-sm-6">
                  <b-form-select
                    id="user_role"
                    v-model="user.role_id"
                    text-field="name"
                    value-field="id"
                    :options="roles"
                  >
                  </b-form-select>
                </div>
              </div>
            </b-form-group>

            <br />

            <h5>User Groups</h5>

            <b-card class="reports-card" body-class="p-1 pt-3">
              <div class="row align-items-center">
                <div class="col-sm-5 pr-0">
                  <h6>Available Groups</h6>
                  <b-card
                    class="std-border mb-0 group-sub-card"
                    body-class="p-1"
                  >
                    <b-list-group
                      v-if="!this.availableGroups.length"
                      class="text-center"
                    >
                      <b-list-group-item
                        ><i>No Groups to list ... </i></b-list-group-item
                      >
                    </b-list-group>
                    <b-list-group v-else>
                      <div
                        v-for="(group, index) in availableGroups"
                        :key="index"
                      >
                        <b-list-group-item
                          class="p-1"
                          :class="[
                            { selected: group.id === selectedGroup.id },
                            'p-1'
                          ]"
                          @click="selectGroup(group)"
                        >
                          {{ group.name }}
                        </b-list-group-item>
                      </div>
                    </b-list-group>
                  </b-card>
                </div>
                <div class="col-sm-2">
                  <div class="group-control">
                    <div class="row text-center">
                      <div class="col-sm-12">
                        <b-button
                          size="sm"
                          variant="primary"
                          class="font-weight-bold"
                          :disabled="!selectedGroup.id"
                          @click="addGroup()"
                          v-html="'&nbsp;&nbsp;&#187;&nbsp;&nbsp;'"
                        ></b-button>
                      </div>
                    </div>
                    <div class="row text-center pt-1">
                      <div class="col-sm-12">
                        <b-button
                          size="sm"
                          variant="secondary"
                          class="font-weight-bold"
                          :disabled="!selectedGroup.id"
                          @click="removeGroup()"
                          v-html="'&nbsp;&nbsp;&#171;&nbsp;&nbsp;'"
                        ></b-button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-5 pl-0">
                  <h6>Selected Groups</h6>
                  <b-card
                    class="std-border mb-0 group-sub-card"
                    body-class="p-1"
                  >
                    <b-list-group
                      v-if="!this.selectedGroups.length"
                      class="text-center"
                    >
                      <b-list-group-item
                        ><i>No Groups selected ... </i></b-list-group-item
                      >
                    </b-list-group>
                    <b-list-group v-else>
                      <div
                        v-for="(group, index) in selectedGroups"
                        :key="index"
                      >
                        <b-list-group-item
                          class="p-1"
                          :class="[
                            { selected: group.id === selectedGroup.id },
                            'p-1'
                          ]"
                          @click="selectGroup(group)"
                        >
                          {{ group.name }}
                        </b-list-group-item>
                      </div>
                    </b-list-group>
                  </b-card>
                </div>
              </div>
            </b-card>
          </div>
        </div>
        <div slot="footer">
          <b-row class="text-center">
            <b-col md="12">
              <router-link
                tag="button"
                :to="{ path: '/partner_admin/users' }"
                class="btn btn-secondary fw-btn mt-3 mr-3"
              >
                Cancel
              </router-link>
              <b-button
                type="submit"
                variant="primary"
                class="btn btn-primary fw-btn mt-3 mx-3"
                @click="editUser"
                :disabled="$v.$invalid"
                >Update User</b-button
              >
            </b-col>
          </b-row>
        </div>
      </div>
    </v-wait>
  </partner-admin-page-card>
</template>

<script charset="utf-8">
// global
import Vue from 'vue'
import { required, email, alphaNum } from 'vuelidate/lib/validators'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'
import store from '@/store/store'
import router from '@/router/index'
// api
import partnerUsersAPI from '@/api/finapps/partner_admin/users'
import partnerGroupsAPI from '@/api/finapps/partner_admin/groups'

export default {
  name: 'PartnerAdminEditUser',
  components: {
    Cleave
  },
  beforeCreate() {
    let hasPermission = store.getters['Ability/managePartnerAdminBoard']

    if (!hasPermission) {
      Vue.toasted.show('Page access restricted. Please contact admin.', {
        icon: 'chain-broken',
        type: 'error'
      })
      router.push('/dashboard')
    }
  },
  created() {
    this.userID = this.$route.params.id
    this.loadInitData()
  },
  data() {
    return {
      userID: null,
      roles: [],
      groups: [],
      selectedGroup: {},
      user: {
        id: null,
        first_name: null,
        last_name: null,
        email: null,
        company: null,
        title: null,
        phone: null,
        role_id: null,
        group_ids: []
      },
      phoneOptions: {
        phone: true,
        phoneRegionCode: 'US',
        delimiter: '-'
      }
    }
  },
  computed: {
    availableGroups() {
      return this.groups.filter(group => group.available === true)
    },
    selectedGroups() {
      return this.groups.filter(group => group.selected === true)
    }
  },
  methods: {
    loadInitData() {
      this.$wait.start('fetchingDetails')
      Promise.all([this.fetchUserData(), this.fetchPartnerGroups()]).then(
        () => {
          this.prepareUserData()
          this.$wait.end('fetchingDetails')
        }
      )
    },
    fetchUserData() {
      this.$wait.start('fetchingUserData')

      return partnerUsersAPI
        .showUser(this.userID)
        .then(res => {
          this.user = res.user
          this.user.group_ids = res.groups.map(group => group.id)
          this.roles = res.roles
        })
        .finally(() => {
          this.$wait.end('fetchingUserData')
        })
    },
    prepareUserData() {
      this.groups.forEach(group => {
        let isSelected = this.user.group_ids.includes(group.id)
        group.available = !isSelected
        group.selected = isSelected
      })
    },
    fetchPartnerGroups() {
      this.$wait.start('fetchingGroups')

      return partnerGroupsAPI
        .all()
        .then(res => {
          res.groups.forEach(group => {
            group.available = true
            group.selected = false
          })
          this.groups = res.groups
        })
        .finally(() => {
          this.$wait.end('fetchingGroups')
        })
    },
    addGroup() {
      let selectedGroup = this.groups.find(
        group => group.id === this.selectedGroup.id
      )
      selectedGroup.available = false
      selectedGroup.selected = true
    },
    removeGroup() {
      let selectedGroup = this.groups.find(
        group => group.id === this.selectedGroup.id
      )
      selectedGroup.available = true
      selectedGroup.selected = false
    },
    selectGroup(group) {
      this.selectedGroup = group
    },
    editUser() {
      if (this.user.phone.replaceAll('-', '').length > 11) {
        Vue.toasted.show('Please enter a valid Phone number', {
          icon: 'chain-broken',
          type: 'error'
        })
        return
      }
      this.user.group_ids = this.selectedGroups.map(group => group.id)

      partnerUsersAPI.updateUser(this.user).then(() => {
        Vue.toasted.show('User details updated successfully', {
          icon: 'user-circle',
          type: 'success'
        })
        this.$router.push('/partner_admin/users')
      })
    }
  },
  watch: {
    selectedGroups: function(newVal) {
      this.user.group_ids = newVal.map(group => group.id)
    }
  },
  validations: {
    user: {
      email: {
        required,
        email
      },
      first_name: {
        required,
        alphaNum
      },
      last_name: {
        required,
        alphaNum
      },
      company: {
        required
      },
      title: {
        required
      },
      phone: {
        required,
        mustMatchPhoneLength(value) {
          if (value) {
            let phone = value.replaceAll('-', '')
            return phone.startsWith('1')
              ? phone.length === 11
              : phone.length === 10
          } else {
            return false
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.groups-card {
  .card-header {
    background-color: #ebe0d3;
  }
}

.group-sub-card {
  height: 13rem;
  overflow-y: auto;
}

.list-group-item {
  border: none;
  cursor: pointer;

  &.selected {
    background-color: #f2ece6;
  }
}
</style>

<style lang="scss"></style>
