import Vue from 'vue'
import axios from 'axios'

const fetchGroups = () => {
  return axios
    .get('/api/partner_admin/groups', {
      handleErrors: true
    })
    .then(res => {
      return res.data
    })
}

export default {
  userGroups() {
    return fetchGroups()
  },
  all() {
    return fetchGroups()
  },
  groupUsers(groupIDs) {
    return axios
      .get('/api/partner_admin/groups/users', {
        params: {
          group_ids: groupIDs
        },
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  showGroup(groupID) {
    return axios
      .get(`/api/partner_admin/groups/${groupID}`, {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  createGroup(fiID, userIDs) {
    return axios
      .post(
        '/api/partner_admin/groups',
        {
          fi_id: fiID,
          user_ids: userIDs
        },
        {
          handleErrors: true
        }
      )
      .then(
        res => {
          return res.data
        },
        error => {
          if (error.response.status === 422) {
            Vue.toasted.show(
              'Validation failed. Group with same financial institution already exists.',
              {
                icon: 'chain-broken',
                type: 'error'
              }
            )
          } else {
            Vue.toasted.show(
              'Sorry, an error has occurred. Please try again. If the problem continues, please know that our team has been alerted.',
              {
                icon: 'chain-broken',
                type: 'error'
              }
            )
          }
          return Promise.reject('validation failed')
        }
      )
  },
  updateGroup(group) {
    return axios
      .put(
        `/api/partner_admin/groups/${group.id}`,
        {
          fi_id: group.fi_id,
          user_ids: group.user_ids
        },
        {
          handleErrors: true
        }
      )
      .then(
        res => {
          return res.data
        },
        error => {
          if (error.response.status === 422) {
            Vue.toasted.show(
              `Validation failed. ${error.response.data.message[0]}`,
              {
                icon: 'chain-broken',
                type: 'error'
              }
            )
          } else {
            Vue.toasted.show(
              'Sorry, an error has occurred. Please try again. If the problem continues, please know that our team has been alerted.',
              {
                icon: 'chain-broken',
                type: 'error'
              }
            )
          }
          return Promise.reject('validation failed')
        }
      )
  },
  deleteGroup(groupID) {
    return axios
      .delete(`/api/partner_admin/groups/${groupID}`, {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  }
}
