var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('partner-admin-page-card',{attrs:{"pageName":"Edit User","iconClass":"fa fa-users fa-lg"}},[_c('v-wait',{attrs:{"for":"fetchingDetails"}},[_c('template',{slot:"waiting"},[_c('content-placeholders',{attrs:{"rounded":true}},[_c('content-placeholders-text',{attrs:{"lines":20}})],1)],1),_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-lg-12"},[_c('b-form-group',{attrs:{"label":"First, Last Name","label-for":"name","label-cols":3,"horizontal":true}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('b-form-input',{class:{
                    'is-invalid': _vm.$v.user.first_name.$error,
                    'is-valid': !_vm.$v.user.first_name.$invalid
                  },attrs:{"id":"name","type":"text","placeholder":"First Name"},on:{"blur":function($event){return _vm.$v.user.first_name.$touch()}},model:{value:(_vm.user.first_name),callback:function ($$v) {_vm.$set(_vm.user, "first_name", $$v)},expression:"user.first_name"}}),(!_vm.$v.user.first_name.required)?_c('b-form-invalid-feedback',[_vm._v("First Name can't be blank")]):_vm._e(),(!_vm.$v.user.first_name.alphaNum)?_c('b-form-invalid-feedback',[_vm._v("First Name can be only alphanumeric characters")]):_vm._e()],1),_c('div',{staticClass:"col-sm-6"},[_c('b-form-input',{class:{
                    'is-invalid': _vm.$v.user.last_name.$error,
                    'is-valid': !_vm.$v.user.last_name.$invalid
                  },attrs:{"id":"name","type":"text","placeholder":"Last Name"},on:{"blur":function($event){return _vm.$v.user.last_name.$touch()}},model:{value:(_vm.user.last_name),callback:function ($$v) {_vm.$set(_vm.user, "last_name", $$v)},expression:"user.last_name"}}),(!_vm.$v.user.last_name.required)?_c('b-form-invalid-feedback',[_vm._v("Last Name can't be blank")]):_vm._e(),(!_vm.$v.user.last_name.alphaNum)?_c('b-form-invalid-feedback',[_vm._v("Last Name can be only alphanumeric characters")]):_vm._e()],1)])]),_c('b-form-group',{attrs:{"label":"Email","label-for":"email","label-cols":3,"horizontal":true}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('b-form-input',{class:{
                    'is-invalid': _vm.$v.user.email.$error,
                    'is-valid': !_vm.$v.user.email.$invalid
                  },attrs:{"id":"email","type":"email","placeholder":"Email","value":_vm.user.email,"readonly":""}}),(!_vm.$v.user.email.required)?_c('b-form-invalid-feedback',[_vm._v("Email can't be blank")]):_vm._e(),(!_vm.$v.user.email.email)?_c('b-form-invalid-feedback',[_vm._v(" Please provide a valid email address")]):_vm._e()],1)])]),_c('b-form-group',{attrs:{"label":"Company","label-for":"company","label-cols":3,"horizontal":true}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('b-form-input',{class:{
                    'is-invalid': _vm.$v.user.company.$error,
                    'is-valid': !_vm.$v.user.company.$invalid
                  },attrs:{"id":"company","type":"text","placeholder":"Company"},on:{"blur":function($event){return _vm.$v.user.company.$touch()}},model:{value:(_vm.user.company),callback:function ($$v) {_vm.$set(_vm.user, "company", $$v)},expression:"user.company"}}),(!_vm.$v.user.company.required)?_c('b-form-invalid-feedback',[_vm._v("Company can't be blank")]):_vm._e()],1)])]),_c('b-form-group',{attrs:{"label":"Title","label-for":"title","label-cols":3,"horizontal":true}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('b-form-input',{class:{
                    'is-invalid': _vm.$v.user.title.$error,
                    'is-valid': !_vm.$v.user.title.$invalid
                  },attrs:{"id":"title","type":"text","placeholder":"Title"},on:{"blur":function($event){return _vm.$v.user.title.$touch()}},model:{value:(_vm.user.title),callback:function ($$v) {_vm.$set(_vm.user, "title", $$v)},expression:"user.title"}}),(!_vm.$v.user.title.required)?_c('b-form-invalid-feedback',[_vm._v("Title can't be blank")]):_vm._e()],1)])]),_c('b-form-group',{attrs:{"label":"Phone","label-for":"phone","label-cols":3,"horizontal":true}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('cleave',{staticClass:"form-control",class:{
                    'is-invalid': _vm.$v.user.phone.$error,
                    'is-valid': !_vm.$v.user.phone.$invalid
                  },attrs:{"id":"phone","type":"text","placeholder":"Phone number","options":_vm.phoneOptions},on:{"blur":function($event){return _vm.$v.user.phone.$touch()}},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}}),(!_vm.$v.user.phone.required)?_c('b-form-invalid-feedback',[_vm._v("Phone can't be blank")]):_vm._e(),(!_vm.$v.user.phone.mustMatchPhoneLength)?_c('b-form-invalid-feedback',[_vm._v("Phone number is not valid.")]):_vm._e()],1)])]),_c('b-form-group',{attrs:{"label":"User Role","label-for":"user_role","label-cols":3,"horizontal":true}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('b-form-select',{attrs:{"id":"user_role","text-field":"name","value-field":"id","options":_vm.roles},model:{value:(_vm.user.role_id),callback:function ($$v) {_vm.$set(_vm.user, "role_id", $$v)},expression:"user.role_id"}})],1)])]),_c('br'),_c('h5',[_vm._v("User Groups")]),_c('b-card',{staticClass:"reports-card",attrs:{"body-class":"p-1 pt-3"}},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-sm-5 pr-0"},[_c('h6',[_vm._v("Available Groups")]),_c('b-card',{staticClass:"std-border mb-0 group-sub-card",attrs:{"body-class":"p-1"}},[(!this.availableGroups.length)?_c('b-list-group',{staticClass:"text-center"},[_c('b-list-group-item',[_c('i',[_vm._v("No Groups to list ... ")])])],1):_c('b-list-group',_vm._l((_vm.availableGroups),function(group,index){return _c('div',{key:index},[_c('b-list-group-item',{staticClass:"p-1",class:[
                          { selected: group.id === _vm.selectedGroup.id },
                          'p-1'
                        ],on:{"click":function($event){return _vm.selectGroup(group)}}},[_vm._v(" "+_vm._s(group.name)+" ")])],1)}),0)],1)],1),_c('div',{staticClass:"col-sm-2"},[_c('div',{staticClass:"group-control"},[_c('div',{staticClass:"row text-center"},[_c('div',{staticClass:"col-sm-12"},[_c('b-button',{staticClass:"font-weight-bold",attrs:{"size":"sm","variant":"primary","disabled":!_vm.selectedGroup.id},domProps:{"innerHTML":_vm._s('&nbsp;&nbsp;&#187;&nbsp;&nbsp;')},on:{"click":function($event){return _vm.addGroup()}}})],1)]),_c('div',{staticClass:"row text-center pt-1"},[_c('div',{staticClass:"col-sm-12"},[_c('b-button',{staticClass:"font-weight-bold",attrs:{"size":"sm","variant":"secondary","disabled":!_vm.selectedGroup.id},domProps:{"innerHTML":_vm._s('&nbsp;&nbsp;&#171;&nbsp;&nbsp;')},on:{"click":function($event){return _vm.removeGroup()}}})],1)])])]),_c('div',{staticClass:"col-sm-5 pl-0"},[_c('h6',[_vm._v("Selected Groups")]),_c('b-card',{staticClass:"std-border mb-0 group-sub-card",attrs:{"body-class":"p-1"}},[(!this.selectedGroups.length)?_c('b-list-group',{staticClass:"text-center"},[_c('b-list-group-item',[_c('i',[_vm._v("No Groups selected ... ")])])],1):_c('b-list-group',_vm._l((_vm.selectedGroups),function(group,index){return _c('div',{key:index},[_c('b-list-group-item',{staticClass:"p-1",class:[
                          { selected: group.id === _vm.selectedGroup.id },
                          'p-1'
                        ],on:{"click":function($event){return _vm.selectGroup(group)}}},[_vm._v(" "+_vm._s(group.name)+" ")])],1)}),0)],1)],1)])])],1)]),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('b-row',{staticClass:"text-center"},[_c('b-col',{attrs:{"md":"12"}},[_c('router-link',{staticClass:"btn btn-secondary fw-btn mt-3 mr-3",attrs:{"tag":"button","to":{ path: '/partner_admin/users' }}},[_vm._v(" Cancel ")]),_c('b-button',{staticClass:"btn btn-primary fw-btn mt-3 mx-3",attrs:{"type":"submit","variant":"primary","disabled":_vm.$v.$invalid},on:{"click":_vm.editUser}},[_vm._v("Update User")])],1)],1)],1)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }